import { NotificacionesService } from './../../../services/notificaciones.service';
import { ChatService } from '../../../services/chat.service';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ColegiosService } from 'src/app/services/colegios.service';
import { NumeroalertasService } from 'src/app/admin/services/numeroalertas.service';
import { AlertasService } from 'src/app/services/alertas.service';

@Component({
  selector: 'app-hilo',
  templateUrl: './hilo.component.html',
  styleUrls: ['./hilo.component.css'],
})
export class HiloComponent implements OnInit, OnDestroy {
  mainuserid: string;
  searchValue = '';
  results: any;
  chatmessage = '';
  receiverid: string;
  chats = [];
  chatmessages = [];
  datecu: Date;
  elotrousaurio: any;
  urluid: string;
  /*nuevo */
  grupodechats = [];
  chatorimessa = [];
  userchat: any;
  chatusers = [];
  chatload = false;
  mainuseremail: any;
  usuario: any;
  cole: any;
  idSupervisor: any = '';
  obsNotificacion: any = null;
  mensajesAlmacenados = [];
  formatoChatIA: any = {};
  alerta24h = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private notificacionesService: NotificacionesService,
    private userService: UserService,
    private chatService: ChatService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private _colegiosService: ColegiosService,
    private _numeroAlertas: NumeroalertasService,
    private _alertService: AlertasService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.urluid = params['uid'];
      this.chatService.setChatActual(this.urluid);
      const urlsplit = this.urluid.split('|');
      if (urlsplit[0] === urlsplit[1]) {
        return;
      }
      this.authenticationService.getStatus().subscribe(
        (status) => {
          this.mainuserid = status.uid;
          this.mainuseremail = status.email;
          if (urlsplit[0] != this.mainuserid) {
            this.elotrousaurio = urlsplit[0];
          } else {
            this.elotrousaurio = urlsplit[1];
          }
          if (this.elotrousaurio) {
            this.userService
              .getUserById(this.elotrousaurio)
              .subscribe((userchat) => {
                this.userchat = userchat[0].payload.doc.data();
                if (!this.chatusers[this.userchat.uid]) {
                  this.chatusers[this.userchat.uid] =
                    userchat[0].payload.doc.data();
                }
              });
            // let cortarObservable = true;
            this.obsNotificacion = this.notificacionesService
              .getNotificacionesMensajesDeEmisor(
                this.mainuserid,
                this.elotrousaurio
              )
              .subscribe((mensajes) => {
                // if (cortarObservable) {
                // cortarObservable = false;
                // console.log('mensajes hilo: ', mensajes);
                mensajes.forEach((mensaje) => {
                  let id = mensaje.payload.doc.id;
                  this.notificacionesService.removeNotificacion(id).then();
                });
                // }
              });
          }

          let suscription = this.userService
            .getUserById(this.mainuserid)
            .subscribe((user) => {
              this.usuario = user[0].payload.doc.data();

              let colegioSus = this._colegiosService
                .getColegioByIdNow(this.usuario.colegios[0])
                .subscribe((colegio) => {
                  this.cole = colegio.data();

                  suscription.unsubscribe();
                  this.userService
                    .getSupervisor(colegio.id)
                    .subscribe((usuario) => {
                      if (!usuario.empty) {
                        var usua = usuario.docs[0].data();
                        this.idSupervisor = usua['uid'];
                      }
                      colegioSus.unsubscribe();
                    });

                  // this.userService.getUsersNow().subscribe((usuarios) => {
                  //   usuarios.forEach((usuario) => {
                  //     var usua = usuario.data();
                  //     if (
                  //       usua['colegios'][0] == colegio.id &&
                  //       usua['rol'] == 'supervisor'
                  //     ) {
                  //       this.idSupervisor = usua['uid'];
                  //     }
                  //   });
                  //   colegioSus.unsubscribe();
                  // });
                });
            });
          this.buscarSiExisteAlerta();
          this.cargarmensajes();
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }
  ngOnDestroy(): void {
    if (this.obsNotificacion != null) {
      this.obsNotificacion.unsubscribe();
    }
  }

  buscarSiExisteAlerta() {
    const members = {
      members: [
        { uid: this.mainuserid, join: true },
        { uid: this.elotrousaurio, join: true },
      ],
    };
    const ids = [this.mainuserid, this.elotrousaurio].sort();
    const chatid = ids.join('|');
    console.log('buscando alerta', chatid);
    this._alertService.getAlertaByIdChat(chatid).subscribe((alerta:any) => {
      // if (alerta.length > 0) {
      //   this.alerta24h = alerta[0].payload.doc;
      // }
      if (alerta.length > 0) {
        const alertaDoc = alerta[0].payload.doc;
        const alertaData = alertaDoc.data();
        const fechaAlerta = alertaData.fechaAlerta.toDate(); // Convierte el timestamp a Date
        // // Calcula la diferencia en horas entre la fecha actual y la fecha de la alerta
        const ahora = new Date();
        const diferenciaHoras = (ahora.getTime() - fechaAlerta.getTime()) / (1000 * 60 * 60); // Si la diferencia es menor a 12 horas, guarda la alerta en this.alerta24h
        if (diferenciaHoras < 12) {
          this.alerta24h = alertaDoc;
        }
      }
    });
  }

  sendmessage() {
    let len = this.translate.getDefaultLang();

    if (this.chatmessage != '') {
      this.datecu = new Date();
      const message = {
        sender: this.mainuserid,
        receiver: this.elotrousaurio,
        message: this.chatmessage,
        fecha: this.datecu,
        verlo: [this.mainuserid, this.elotrousaurio],
        nuevo: true,
      };
      this.chatmessage = '';
      const members = {
        members: [
          { uid: this.mainuserid, join: true },
          { uid: this.elotrousaurio, join: true },
        ],
      };
      const ids = [this.mainuserid, this.elotrousaurio].sort();
      const chatid = ids.join('|');
      this.chatService
        .sendmessage(chatid, message, members)
        .then((datames) => {
          // this.chatmessage = '';
          this.cargarmensajes();
          // this.analisis(message);
          this.analisisChat(message);

          var notification = {
            user: this.elotrousaurio,
            texto: '🗨️ ' + message.message,
            url: '/dashboard/mensajes/chat/' + chatid,
            verlo: this.elotrousaurio,
            fecha: new Date(),
            emisor: this.mainuserid,
            mensaje: true,
          };

          // switch (len) {
          //   case 'en':
          //     notification.texto = "You have received a new message"
          //     break;
          //   case 'es':
          //     notification.texto = "Has recibido un nuevo mensaje"
          //     break;

          //   default:
          //     break;
          // }

          // const notification = {
          //   user: this.elotrousaurio,
          //   texto: 'Ha recibido un nuevo mensaje',
          //   url: '/dashboard/mensajes/chat/' + chatid,
          //   verlo: this.elotrousaurio,
          //   fecha: new Date(),
          //   emisor: this.mainuserid
          // };
          this.notificacionesService.createdNotificacion(notification);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  analisis(message) {
    var dataTrans = {
      text: message.message,
    };
    var jsonbody = JSON.stringify(dataTrans);
    const url =
      'https://us-central1-the-campus-56b06.cloudfunctions.net/app/analyze-sentiment';
    fetch(url, {
      method: 'POST',
      body: jsonbody,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('data', data);
        if (data.predictions == 'toxico') {
          this._numeroAlertas.getNumber().subscribe((alerta) => {
            alerta.forEach((alertaDato) => {
              var alertita: any = alertaDato.data();

              var number: number = alertita.numero;
              this._numeroAlertas.editNumber();
              var alerta = {
                avatarColegio: this.cole.avatar,
                estado: 'pendiente',
                fechaAlerta: message.fecha,
                idAlerta: number,
                idColegio: this.cole.nombre,
                idSupervisor: this.idSupervisor,
                idUsuario: message.sender,
                mensaje: 'Posible amenaza con bullying',
                mostrarSupervisor: true,
                avatarAlumno: this.usuario.avatar,
                reportada: true,
                nombre: this.usuario.usuario,
              };

              console.log(alerta);

              this._alertService
                .createdAlerta(alerta)
                .then((alertaC) => console.log('Alerta creada'));
            });
          });
        }
      });
  }

  analisisChat(message) {
    // this.addMessageIA(message)
    const members = {
      members: [
        { uid: this.mainuserid, join: true },
        { uid: this.elotrousaurio, join: true },
      ],
    };
    const ids = [this.mainuserid, this.elotrousaurio].sort();
    const chatid = ids.join('|');

    var dataTrans = this.formatoChatIA;
    var jsonbody = JSON.stringify(dataTrans);
    const url =
      'https://us-central1-the-campus-56b06.cloudfunctions.net/app/analyze-and-translate-chat';
    fetch(url, {
      method: 'POST',
      body: jsonbody,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.total_toxic_messages > 0) {
          console.log('alerta24h', this.alerta24h);
          if (this.alerta24h != null) {
            var alerta = {
              estado: 'pendiente',
              fechaAlerta: message.fecha,
              idAlerta: this.alerta24h.data().idAlerta,
              idChat: this.alerta24h.data().idChat,
              idColegio: this.alerta24h.data().idColegio,
              idSupervisor: this.alerta24h.data().idSupervisor,
              idUsuario: this.alerta24h.data().idUsuario,
              mensaje: data.global_summary,
              // mensaje: 'Posible amenaza',
              mostrarSupervisor: true,
              reportada: true,
              nombre: this.alerta24h.data().nombre,
              resumen: data.global_summary,
            };
            this._alertService.editAlerta(this.alerta24h.id, alerta);
          } else {
            this._numeroAlertas.getNumber().subscribe((alerta) => {
              alerta.forEach((alertaDato) => {
                var alertita: any = alertaDato.data();

                var number: number = alertita.numero;
                this._numeroAlertas.editNumber();
                var alerta = {
                  estado: 'pendiente',
                  fechaAlerta: message.fecha,
                  idAlerta: number,
                  idChat: chatid,
                  idColegio: this.cole.nombre,
                  idSupervisor: this.idSupervisor,
                  idUsuario: message.sender,
                  mensaje: data.global_summary,
                  // mensaje: 'Posible amenaza',
                  mostrarSupervisor: true,
                  reportada: true,
                  nombre: this.usuario.usuario,
                  resumen: data.global_summary,
                };
                console.log(alerta);

                this._alertService
                  .createdAlerta(alerta)
                  .then((alertaC) => console.log('Alerta creada'));
              });
            });
          }
        }
      });
  }

  // analisisChat(message) {
  //   // this.addMessageIA(message)

  //   var dataTrans = this.formatoChatIA;
  //   var jsonbody = JSON.stringify(dataTrans);
  //   const url =
  //     'https://us-central1-the-campus-56b06.cloudfunctions.net/app/analyze-sentiment-chat';
  //   fetch(url, {
  //     method: 'POST',
  //     body: jsonbody,
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log('data', data);
  //       // if (data.predictions == 'toxico') {
  //       //   this._numeroAlertas.getNumber().subscribe((alerta) => {
  //       //     alerta.forEach((alertaDato) => {
  //       //       var alertita: any = alertaDato.data();

  //       //       var number: number = alertita.numero;
  //       //       this._numeroAlertas.editNumber();
  //       //       var alerta = {
  //       //         avatarColegio: this.cole.avatar,
  //       //         estado: 'pendiente',
  //       //         fechaAlerta: message.fecha,
  //       //         idAlerta: number,
  //       //         idColegio: this.cole.nombre,
  //       //         idSupervisor: this.idSupervisor,
  //       //         idUsuario: message.sender,
  //       //         mensaje: 'Posible amenaza con bullying',
  //       //         mostrarSupervisor: true,
  //       //         avatarAlumno: this.usuario.avatar,
  //       //         reportada: true,
  //       //         nombre: this.usuario.usuario,
  //       //       };

  //       //       console.log(alerta);

  //       //       this._alertService
  //       //         .createdAlerta(alerta)
  //       //         .then((alertaC) => console.log('Alerta creada'));
  //       //     });
  //       //   });
  //       // }
  //     });
  // }

  // analisis(message) {
  //   let mensajeLower = message.message.toLowerCase();
  //   var dataTrans = {
  //     mensaje: mensajeLower,
  //   };
  //   var jsonbody = JSON.stringify(dataTrans);
  //   const url =
  //     'https://us-central1-the-campus-56b06.cloudfunctions.net/app/IABullying';
  //   fetch(url, {
  //     method: 'POST',
  //     body: jsonbody,
  //   })
  //     .then((response) => response.text())
  //     .then((data) => {
  //       if (data == 'true') {
  //         this._numeroAlertas.getNumber().subscribe((alerta) => {
  //           alerta.forEach((alertaDato) => {
  //             var alertita: any = alertaDato.data();

  //             var number: number = alertita.numero;
  //             this._numeroAlertas.editNumber();
  //             var alerta = {
  //               avatarColegio: this.cole.avatar,
  //               estado: 'pendiente',
  //               fechaAlerta: message.fecha,
  //               idAlerta: number,
  //               idColegio: this.cole.nombre,
  //               idSupervisor: this.idSupervisor,
  //               idUsuario: message.sender,
  //               mensaje: message.message,
  //               mostrarSupervisor: true,
  //               avatarAlumno: this.usuario.avatar,
  //               reportada: false,
  //               nombre: this.usuario.usuario,
  //             };

  //             this._alertService
  //               .createdAlerta(alerta)
  //               .then((alertaC) => console.log('Alerta creada'));
  //           });
  //         });
  //       }
  //     });
  // }

  cargarmensajes() {
    if (!this.grupodechats[this.urluid]) {
      this.chatService
        .getMessages(this.urluid, this.mainuserid)
        .subscribe((message) => {
          const messagechatid = [
            message[0]?.payload.doc.data().sender,
            message[0]?.payload.doc.data().receiver,
          ].sort();
          const themeschaid = messagechatid.join('|');

          this.grupodechats[themeschaid] = message;
          console.log('mensaje', message);
          this.mensajesAlmacenados = [];
          this.mensajesAlmacenados = message;
          this.formatoChatIA = this.darFormatoMensajesIA12h();
          console.log(this.formatoChatIA);

          if (
            this.mainuserid !==
            this.grupodechats[this.urluid][0]?.payload.doc.data().receiver
          ) {
            this.elotrousaurio =
              this.grupodechats[this.urluid][0]?.payload.doc.data().receiver;
          } else {
            this.elotrousaurio =
              this.grupodechats[this.urluid][0]?.payload.doc.data().sender;
          }

          this.userService
            .getUserById(
              this.grupodechats[this.urluid][0]?.payload.doc.data().receiver
            )
            .subscribe((userchat) => {
              this.userchat = userchat[0]?.payload.doc.data();
              if (!this.chatusers[this.userchat.uid]) {
                this.chatusers[this.userchat.uid] =
                  userchat[0]?.payload.doc.data();
              }
            });

          this.userService
            .getUserById(
              this.grupodechats[this.urluid][0]?.payload.doc.data().sender
            )
            .subscribe((userchat) => {
              this.userchat = userchat[0]?.payload.doc.data();
              if (!this.chatusers[this.userchat.uid]) {
                this.chatusers[this.userchat.uid] =
                  userchat[0]?.payload.doc.data();
              }
            });

          this.chatload = true;
        });
    } else {
      if (
        this.mainuserid !==
        this.grupodechats[this.urluid][0]?.payload.doc.data().receiver
      ) {
        this.elotrousaurio =
          this.grupodechats[this.urluid][0]?.payload.doc.data().receiver;
      } else {
        this.elotrousaurio =
          this.grupodechats[this.urluid][0]?.payload.doc.data().sender;
      }
    }
  }

  scrolltobottom() {
    const objDiv = document.getElementById('themessagesbox');
    objDiv.scrollTop = objDiv.scrollHeight + 1000;
  }

  darFormatoMensajesIA() {
    const format = {
      chat_id: this.urluid,
      messages: [],
    };
    this.mensajesAlmacenados.forEach((mensaje) => {
      // console.log(mensaje.payload.doc?.data())
      var datos = mensaje.payload.doc?.data();

      const milisegundos =
        datos.fecha.seconds * 1000 + datos.fecha.nanoseconds / 1000000;

      // Crear un nuevo objeto Date con los milisegundos
      const fecha = new Date(milisegundos);

      // Devolver la fecha en formato ISO
      // console.log("fecha",  fecha.toISOString())

      format.messages.push({
        sender: datos.sender,
        content: datos.message,
        timestamp: fecha,
      });
    });
    return format;
  }
  darFormatoMensajesIA12h() {
    const format = {
      chat_id: this.urluid,
      messages: [],
    };

    const ahora: any = new Date();
    const milisegundos12Horas = 12 * 60 * 60 * 1000;

    this.mensajesAlmacenados.forEach((mensaje) => {
      var datos = mensaje.payload.doc?.data();

      const milisegundos =
        datos.fecha.seconds * 1000 + datos.fecha.nanoseconds / 1000000;

      const fecha: any = new Date(milisegundos);

      if (ahora - fecha <= milisegundos12Horas) {
        format.messages.push({
          sender: datos.sender,
          content: datos.message,
          timestamp: fecha,
        });
      }
    });

    return format;
  }

  addMessageIA(message) {
    const milisegundos =
      message.fecha.seconds * 1000 + message.fecha.nanoseconds / 1000000;

    // Crear un nuevo objeto Date con los milisegundos
    const fecha = new Date(milisegundos);

    this.formatoChatIA.messages.push({
      sender: message.sender,
      content: message.message,
      timestamp: fecha,
    });
    return this.formatoChatIA;
  }
}
